import React, { forwardRef, useContext, useEffect, useState } from "react";
import { APIProviderContext } from "../api";
import Loader from "../loader";
import FlipMove from "react-flip-move";

const PENDING_TASKS_LIMIT = 10;

const PendingItem = forwardRef((props, ref)=>{

    const { item, showRank, rank } = props;

    return (
        <div className="relative flex flex-col gap-2 p-4 rounded-lg border border-border bg-card">
            <h3 className="truncate">{item.name}</h3>
            {
                showRank && (
                    <div className="text-[12px] p-2 rounded-full bg- absolute top-0 right-0 translate-x-[30%] translate-y-[-50%] bg-red-600 text-foreground px-2 py-1">
                        #{rank}
                    </div>
                )
            }
        </div>
    );
});

PendingItem.displayName = "PendingItem";

function PendingItems() {

    const { apiAction } = useContext(APIProviderContext);

    const [pendingItems, setPendingItems] = useState([]);
    const [loading, setLoading] = useState(false);

    function loadPending(){

        apiAction("GET", "task/pending")
            .then(data=>{

                var pending = data?.pendingTasks ?? [];

                setPendingItems(pending);
                setLoading(false);
            })
            .catch(err=>{
                console.error(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);

        loadPending();

        var interval = setInterval(() => {
            loadPending();
        }, 5000);

        return () => {
            if(interval){
                clearInterval(interval);
            }
        };
    }, []);

  return (
    <div className="flex flex-col gap-4 border p-4 border-border rounded-lg bg-card min-h-full">
        <h2 className="text-2xl font-bold">
            Upcoming {
                !loading && (
                    <span className="text-sm text-gray-500">({pendingItems.length})</span>
                )
            }
        </h2>
        <div className="flex flex-col gap-2">
            {
                loading ? (
                    <>
                        <div className="flex flex-col gap-2">
                        <Loader 
                                size={6}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-col gap-2">
                            {pendingItems.map((item, index) => (
                                <PendingItem showRank={true} rank={index + 1} key={item.uuid} item={item} />
                            ))}
                        </div>
                        {
                            pendingItems?.length === 0 && (
                                <div className="flex flex-col italic text-gray-500 gap-2">
                                    <p>Nothing here yet!</p>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    </div>
  );
}

export {PendingItems, PendingItem};