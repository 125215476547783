import { Input } from "antd";
import React, { useState, useRef, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { APIProviderContext } from "../api";
import Loader from "../loader";
import { useParams, useNavigate } from "react-router-dom";
import { PER_TASK_SECONDS, TASK_STATUS } from "../../lib/utils";

function QueueDisplay({}) {


  const { queueID } = useParams();

  const { apiAction } = useContext(APIProviderContext);
  const [loading, setLoading] = useState(false);
  const [queueData, setQueueData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {

    var interval;
    
    if(queueID){
      setLoading(true);
      apiAction("GET", `task/details/${queueID}`)
        .then(data => {
          setQueueData(data?.queueData);
          setLoading(false);

          if(
            data?.queueData?.status === TASK_STATUS.PROCESSING || 
            data?.queueData?.status === TASK_STATUS.PENDING ||
            data?.queueData?.status === TASK_STATUS.COMPLETED && data?.queueData?.rank === 1
          ){
            interval = setInterval(() => {
              apiAction("GET", `task/details/${queueID}`)
                .then(data => {
                  setQueueData(data?.queueData);

                  if(
                    data?.queueData?.status === TASK_STATUS.COMPLETED && data?.queueData?.rank === 0
                  ){
                    clearInterval(interval);
                  }

                })
                .catch(err => {
                  toast.error("Failed to fetch queue data");
                  console.error(err);
                });
            }, 5000);

          }
        })
        .catch(err => {
          toast.error("Failed to fetch queue data");
          console.error(err);
          setLoading(false);
        });
    }

    return () => {
      if(interval){
        clearInterval(interval);
      }
    };

  }, [queueID]);

  

  var timeLeft = parseInt(PER_TASK_SECONDS * queueData?.rank / 60) + 1;
  
  return (
    <div className="w-full flex flex-col gap-4 text-center">
      {
        loading ? (
          <>
            <Loader 
                                size={6}
                            />
          </>
        ) : (
          <>
            {
              queueData?.status === TASK_STATUS.PENDING && (
                <>
                <p className="text-[28px]">
                  Your are #{queueData?.rank} in the queue. Your loved ones will be up in the altar in {timeLeft} min{timeLeft > 1 ? "s" : ""}
                </p>
                <p>
                    Get your camera ready to take a picture of the altar
                </p>
                <button 
                  onClick={() => {
                    navigate("/");
                  }}
                  className="ep-action-btn">
                  Finish
                </button>
                </>
              )
            }
            {
              queueData?.status === TASK_STATUS.PROCESSING && (
                <>
                <p className="text-[28px]">
                  You&apos;re up next. We are creating a personalized experience for you. 
                </p>
                <p>
                    Get your camera ready to take a picture of the altar
                </p>
                </>
              )
            }
            {
              queueData?.status === TASK_STATUS.COMPLETED && queueData?.rank === 1 && (
                <p className="text-[28px]">
                  Your loved ones are up in the altar 🎉
                </p>
              )
            }
            {
              queueData?.status === TASK_STATUS.COMPLETED && queueData?.rank === 0 && (
                <>
                <p className="text-[28px]">
                  Hope you enjoyed the experience. 😊
                </p>
                <a href="/" className="text-[18px] underline">
                  Start again?
                </a>
                </>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default QueueDisplay;