import React from "react";

function Header() {
  return (
    <div className="w-full p-2 flex justify-between items-center">
          <img src="/images/colors-mexico.svg" alt="logo" className="w-[60px] h-auto" />
          <img src="/images/dod.svg" alt="logo" className="w-[50px] h-auto" />
        </div>
  );
}

export default Header;