import { Checkbox, Input, Select } from "antd";
import React, { useState, useRef, useContext } from "react";
import toast from "react-hot-toast";
import { APIProviderContext } from "../api";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";
import classNames from "classnames";
import {phone} from "phone";
import * as EmailValidator from "email-validator";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop-image";

var LIMITS = {
  selfname: {
    maxLength: 100,
  },
  selfphone: {
    maxLength: 20,
  },
  selfemail: {
    maxLength: 100,
  },
};

const CheckboxGroup = Checkbox.Group;

function SelectWithOtherInput({value, onChange, options}){

  const isValueFromOther = value && (options?.find(option => option.value === value) === undefined);

  return (
    <div className="input-group">
      <Select 
        className="w-full mb-2"
        placeholder="-"
      value={
        isValueFromOther ? "Other" : value
      } onChange={(e) => onChange(e)} options={[
        ...options,
        {label: "Other", value: "Other"},
      ]} />
        {
          isValueFromOther && (
            <Input value={value === "Other" ? "" : value} onChange={(e) => {
              if(e.target.value === ""){
                onChange("Other");
              } else {
                onChange(e.target.value);
              }
            }}
            maxLength={200}
            />
          )
        }
    </div>
  );
}

function RequiredField(){
  return (
      <span className="text-red-500 ml-1">*</span>
  );
}

function RemembranceForm() {

  const [stage, setStage] = useState("intro");
  const [formData, setFormData] = useState({});
  const imageUploadRef = useRef(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedConfig, setCroppedConfig] = useState(null);

  const { apiAction } = useContext(APIProviderContext);

  const [isValidatingImage, setIsValidatingImage] = useState(false);
  const [isEnqueuing, setIsEnqueuing] = useState(false);
  const [isPersonalityOtherSelected, setIsPersonalityOtherSelected] = useState(false);
  const [personalityOtherValue, setPersonalityOtherValue] = useState("");

  const [isInterestsOtherSelected, setIsInterestsOtherSelected] = useState(false);
  const [interestsOtherValue, setInterestsOtherValue] = useState("");

  const navigate = useNavigate();

  async function handleSubmission(){

    var selfname = formData?.selfname;
    var selfphone = formData?.selfphone;
    var selfemail = formData?.selfemail;
    var name = formData?.name;
    var occupation = formData?.occupation;
    var location = formData?.location;
    var personality = formData?.personality;
    var interests = formData?.interests;
    var habit = formData?.habit;
    var relationshipwithdeath = formData?.relationshipwithdeath;
    var fearschallenges = formData?.fearschallenges;
    var achievements = formData?.achievements;
    var phrase = formData?.phrase;

    // Remove trailing and leading spaces
    selfname = selfname?.trim?.();
    selfphone = selfphone?.trim?.();
    selfemail = selfemail?.trim?.();
    name = name?.trim?.();
    occupation = occupation?.trim?.();
    location = location?.trim?.();
    personality = personality?.filter(item => item !== "Other");
    interests = interests?.filter(item => item !== "Other");
    habit = habit?.trim?.();
    relationshipwithdeath = relationshipwithdeath?.trim?.();
    fearschallenges = fearschallenges?.trim?.();
    achievements = achievements?.trim?.();
    phrase = phrase?.trim?.();

    if(isPersonalityOtherSelected){
      personality = [...personality ?? [], personalityOtherValue];
    }

    if(isInterestsOtherSelected){
      interests = [...interests ?? [], interestsOtherValue];
    }

    console.log(personality, interests);

    if(stage === "intro"){
      setStage("yourself");
      return;
    }

    if(stage === "yourself"){

      if(!selfname){
        toast.error("Please fill in your name");
        return;
      }

      if(selfname?.length < 5){
        toast.error("Please fill in a longer name");
        return;
      }

      if(selfphone){
        if(selfphone?.length < 5){
          toast.error("Please fill in a longer phone number");
          return;
        }
  
        // Check if phone number is valid
        if(!phone(selfphone)?.isValid){
          toast.error("Please fill in a valid phone number");
          return;
        }
      }


      if(!selfemail){
        toast.error("Please fill in your email");
        return;
      }

      if(!EmailValidator.validate(selfemail)){
        toast.error("Please fill in a valid email");
        return;
      }

      setStage("photo");

    }

    if(stage === "photo"){

      imageUploadRef.current.click();
    }

    if(stage === "uploaded"){

      // Check if photo is uploaded
      if(!formData?.photo){
        toast.error("Please upload a photo");
        return;
      }

      try {

        if(isValidatingImage){
          return;
        }

        setIsValidatingImage(true);

        // Validate image 
        var image = await apiAction("POST", "task/image-validate-upload", formData, "formdata");

        setIsValidatingImage(false);

        if(image?.photoUrl){
          setFormData({...formData, photoUrl: image.photoUrl});
        } else {
          toast.error("Failed to validate image");
          return;
        }

      } catch(err){
        setIsValidatingImage(false);
        toast.error(err?.response?.data?.response || "Failed to validate image");
        return;
      }
      

      setStage("details");
      

    }

    if(stage === "details"){

      // Check if name, food, color, characteristic are filled
      if(!formData?.name){
        toast.error("Please fill in a name or nickname");
        return;
      }

      if(!formData?.occupation){
        toast.error("Please fill in an occupation");
        return;
      }

      if(!formData?.location){
        toast.error("Please fill in a location");
        return;
      }

      if(!formData?.personality?.length){
        toast.error("Please select at least one personality trait");
        return;
      }

      if(!formData?.interests?.length){
        toast.error("Please select at least one interest");
        return;
      }

      if(!formData?.habit){
        toast.error("Please fill in a habit");
        return;
      }

      if(!formData?.relationshipwithdeath){
        toast.error("Please fill in a relationship with death");
        return;
      }

      if(!formData?.fearschallenges?.length){
        toast.error("Please select at least one fear or challenge");
        return;
      }

      // Todo check here
      if(isEnqueuing){
        return;
      }

      setIsEnqueuing(true);

      // Add to queue
      apiAction("POST", "task/enqueue", {
        ...formData,
        selfname,
        selfphone,
        selfemail,
        name,
        occupation,
        location,
        personality,
        interests,
        habit,
        relationshipwithdeath,
        fearschallenges,
        achievements,
        phrase,
      })
      .then(data => {
        var newTaskUlid = data?.newTaskUlid;
        
        if(newTaskUlid){
          navigate(`/queue/${newTaskUlid}`);
        }

        setIsEnqueuing(false);

      })
      .catch(err => {
        console.error(err?.response?.data?.response ?? err);
        setIsEnqueuing(false);
        toast.error(err?.response?.data?.response || "Failed to add to queue");
      });

      
      
    }
  }

  function handleImageUpload(e){
    const file = e?.target?.files?.[0];

    if(!file){
      return;
    }

    // Check max file size - todo fix it later
    // if(file?.size > 3 * 1024 * 1024){
    //   toast.error("Please upload a photo smaller than 3MB");
    //   return;
    // }

    // Check if file is an image
    if(!file?.type?.includes("image")){
      toast.error("Please upload a valid image file");
      return;
    }

    setImageBlob(window.URL.createObjectURL(file));
    setFormData({...formData, photo: file});


    setStage("photo-crop");
  }
  
  return (
    <div className="w-full flex flex-col gap-4 text-center">
      <input type="file" className="hidden" accept="image/*" ref={imageUploadRef} onChange={handleImageUpload}  />
      <img src="/images/candle.png" className={
        classNames({
          "w-[150px] h-auto mx-auto": true,
          "!w-[70px]": stage === "uploaded",
        })
      } />
      {
        stage === "intro" && (
          <>
            <p className="text-[20px]">
            Would you like to see your loved ones in the largest digital Day of the Dead altar in the world?
            </p>
            <p>
            (you will need a photo of your loved one to continue)
            </p>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
                Continue
              </button>
          </>
        )
      }
      {
        stage === "yourself" && (
          <div className="flex flex-col gap-4 text-left">
            <div className="bg-card p-4 rounded-lg text-center">
              <p className="text-[16px]">
              The Day of the Dead, or Día de los Muertos, is a vibrant celebration of life and memory, where families gather to honor the spirits of loved ones who have passed. This tradition, steeped in Mexican culture, reflects a deep reverence for the cycle of life and death, turning loss into a beautiful expression of love. Altars, adorned with marigolds, candles, and treasured mementos, create a bridge between worlds, inviting the departed to join the living in a joyful remembrance that reminds us that those we cherish are never truly gone.
              </p>
            </div>
            <p className="text-center font-semibold">
              First Tell us about yourself
            </p>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4">
              <div className="input-group">
                <label htmlFor="name">Full Name<RequiredField /></label>
                <Input id="name" placeholder="James Bond" value={formData?.selfname} 
                  maxLength={LIMITS?.selfname?.maxLength} 
                  onChange={(e) => setFormData({...formData, selfname: e.target.value})}  />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email<RequiredField /></label>
                <Input id="email" 
                  placeholder="james@bond.com"
                  maxLength={LIMITS?.selfemail?.maxLength}
                  value={formData?.selfemail} onChange={(e) => setFormData({...formData, selfemail: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="phone">Phone</label>
                <Input id="phone" 
                  placeholder="+1 234 567 8900"
                  maxLength={LIMITS?.selfphone?.maxLength}
                value={formData?.selfphone} onChange={(e) => {
                  // Only accept numbers spaces dashes and +
                  e.target.value = e.target.value.replace(/[^0-9\s\-+]/g, "");
                  setFormData({...formData, selfphone: e.target.value});
                }} />
              </div>
              <button onClick={() => handleSubmission()} className="ep-action-btn">
                Continue
              </button>
            </div>
          </div>
        )
      }
      {
        stage === "photo" && (
          <>
            <p className="font-bold">
              Now, let’s upload a photo of <br></br> your loved one
            </p>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              Choose Photo
            </button>
          </>
        )
      }
      {
        stage === "photo-crop" && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col gap-4 p-4 items-center justify-center backdrop-blur-sm">
              <p className="text-white text-[18px] font-semibold">
                Crop your photo
              </p>
              <div className="w-[400px] aspect-[1] relative max-w-full">
              <Cropper
                image={imageBlob}
                crop={crop}
                zoom={zoom}
                aspect={9/16}
                minZoom={9/16}
                restrictPosition={false}
                onCropChange={setCrop}
                onCropComplete={(croppedArea, croppedAreaPixels)=>{

                  setCroppedConfig({
                    ...croppedConfig,
                    ...croppedAreaPixels,
                  });


                }}
                onZoomChange={setZoom}
                objectFit="horizontal-cover"
              />
              </div>
              <button onClick={async () => {
                var croppedImage = await getCroppedImg(imageBlob, croppedConfig);
                
                setImageBlob(window.URL.createObjectURL(croppedImage));
                setFormData({...formData, photo: croppedImage});
                setStage("uploaded");

              }} className="ep-action-btn">
                Save
              </button>
            </div>
          </>
        )
      }
      {
        stage === "uploaded" && (
          <>
            <p>
              This is the photo you chose
            </p>
            <img src={imageBlob} alt="loved one" className="rounded-lg" />
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              {
                isValidatingImage ? <Loader 
                  size={6}
                  color="#040316"
                /> : "Continue"
              }
            </button>
            <button onClick={() => {
              imageUploadRef.current.click();
            }} className="ep-action-btn">
              Choose another photo
            </button>
          </>
        )
      }
      {
        stage === "details" && (
          <>
            <p className="text-[18px] font-semibold">
            Finally, let’s create a calverita (poem) for your loved one:
            </p>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4">
              <p className="text-[16px]">
              Calaveritas, playful verses written for the Day of the Dead, honor the departed with humor and wit. These poems, crafted as lighthearted epitaphs, remind us that laughter can transcend even death, celebrating life with a smile.
              </p>
            </div>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4 text-left">
            <div className="input-group">
                <label htmlFor="name">Name or Nickname<RequiredField /></label>
                <Input maxLength={200} id="name" value={formData?.name} onChange={(e) => setFormData({...formData, name: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="occupation">Occupation<RequiredField /></label>
                <SelectWithOtherInput id="occupation" value={formData?.occupation} onChange={(e) => setFormData({...formData, occupation: e})} options={[
                  {label: "Student", value: "Student"},
                  {label: "Teacher", value: "Teacher"},
                  {label: "Doctor", value: "Doctor"},
                  {label: "Lawyer", value: "Lawyer"},
                  {label: "Engineer", value: "Engineer"},
                  {label: "Artist", value: "Artist"},
                  {label: "Musician", value: "Musician"},
                  {label: "Chef", value: "Chef"},
                ]} />

              </div>

              <div className="input-group">
                <label htmlFor="location">Where were they from?<RequiredField /></label>
                {/* <SelectWithOtherInput id="location" value={formData?.location} onChange={(e) => setFormData({...formData, location: e})} options={[
                  {label: "Mexico City", value: "Mexico City"},
                  {label: "New York", value: "New York"},
                  {label: "Los Angeles", value: "Los Angeles"},
                ]} /> */}
                <Input placeholder="Mexico City" id="location" value={formData?.location} onChange={(e) => setFormData({...formData, location: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="personality">Personality<RequiredField /></label>
                <CheckboxGroup
                options={[
                  {label: "Funny", value: "Funny"},
                  {label: "Serious", value: "Serious"},
                  {label: "Quiet", value: "Quiet"},
                  {label: "Outgoing", value: "Outgoing"},
                  {label: "Introvert", value: "Introvert"},
                  {label: "Kind", value: "Kind"},
                  {label: "Mean", value: "Mean"},
                  {label: "Other", value: "Other"},
                ]} 
                  value={formData?.personality}
                  onChange={(e) => {
                    if(e?.length > 3){
                      toast.error("You can only select up to 3 personality traits");
                      return;
                    }
                    if(e?.includes("Other")){
                      setIsPersonalityOtherSelected(true);
                    } else {
                      setIsPersonalityOtherSelected(false);
                      setPersonalityOtherValue("");
                    }

                    setFormData({...formData, personality: e});
                  }}
                />
                {
                  isPersonalityOtherSelected && (
                    <Input placeholder="Other" value={personalityOtherValue} onChange={(e) => setPersonalityOtherValue(e.target.value)} className="mt-2" />
                  )
                }
              </div>
              <div className="input-group">
                <label htmlFor="interests">Interests<RequiredField /></label>
                <CheckboxGroup options={[
                  {label: "Reading", value: "Reading"},
                  {label: "Cooking", value: "Cooking"},
                  {label: "Art", value: "Art"},
                  {label: "Music", value: "Music"},
                  {label: "Sports", value: "Sports"},
                  {label: "Gaming", value: "Gaming"},
                  {label: "Movies", value: "Movies"},
                  {label: "Books", value: "Books"},
                  {label: "Coding", value: "Coding"},
                  {label: "Design", value: "Design"},
                  {label: "Marketing", value: "Marketing"},
                  {label: "Business", value: "Business"},
                  {label: "Other", value: "Other"},
                ]} 
                  value={formData?.interests}
                  onChange={(e) => {
                    if(e?.length > 3){
                      toast.error("You can only select up to 3 interests");
                      return;
                    }
                    if(e?.includes("Other")){
                      setIsInterestsOtherSelected(true);
                    } else {
                      setIsInterestsOtherSelected(false);
                      setInterestsOtherValue("");
                    }
                    setFormData({...formData, interests: e});
                  }}
                />
                {
                  isInterestsOtherSelected && (
                    <Input placeholder="Other" value={interestsOtherValue} onChange={(e) => setInterestsOtherValue(e.target.value)} className="mt-2" />
                  )
                }
              </div>
              <div className="input-group">
                <label htmlFor="habit">Habit<RequiredField /></label>
                <SelectWithOtherInput id="habit" value={formData?.habit} onChange={(e) => setFormData({...formData, habit: e})} options={[
                  {label: "Always late", value: "Always late"},
                  {label: "Loved to talk", value: "Loved to talk"},
                  {label: "Super organized", value: "Super organized"},
                  {label: "Couldn't resist sweets", value: "Couldn't resist sweets"},
                  {label: "Always cracked jokes", value: "Always cracked jokes"},
                  {label: "Constantly checked their phone", value: "Constantly checked their phone"},
                  {label: "Overly cautious", value: "Overly cautious"},
                  {label: "Always talked about work", value: "Always talked about work"},
                ]} />
              </div>
              <div className="input-group">
                <label htmlFor="relationshipwithdeath">Relationship with death (Tone of calaverita)<RequiredField /></label>
                <SelectWithOtherInput id="relationshipwithdeath" value={formData?.relationshipwithdeath} onChange={(e) => setFormData({...formData, relationshipwithdeath: e})} options={[
                  {label: "Playful", value: "Playful"},
                  {label: "Serious", value: "Serious"},
                  {label: "Ironic", value: "Ironic"},
                  {label: "Sarcastic", value: "Sarcastic"},
                  {label: "Light-hearted", value: "Light-hearted"},
                ]} />
              </div>
              <div className="input-group">
                <label htmlFor="fearschallenges">Fears / Challenges<RequiredField /></label>
                <SelectWithOtherInput id="fearschallenges" value={formData?.fearschallenges} onChange={(e) => setFormData({...formData, fearschallenges: e})} options={[
                  {label: "Aging", value: "Aging"},
                  {label: "Losing status", value: "Losing status"},
                  {label: "Change", value: "Change"},
                  {label: "Failure", value: "Failure"},
                  {label: "Social pressure", value: "Social pressure"},
                  {label: "Competition", value: "Competition"},
                  {label: "Loneliness", value: "Loneliness"},
                  {label: "Technology", value: "Technology"},
                ]} />
              </div>
              <div className="input-group">
                <label htmlFor="achievements">Achievements</label>
                <Input id="achievements" value={formData?.achievements} onChange={(e) => setFormData({...formData, achievements: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="phrase">Phrase they often say</label>
                <Input id="phrase" value={formData?.phrase} onChange={(e) => setFormData({...formData, phrase: e.target.value})} />
              </div>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              {
                isEnqueuing ? <Loader 
                  size={6}
                  color="#040316"
                /> : "Submit"
              }
            </button>
            </div>
          </>
        )
      }
    </div>
  );
}

function RemembranceFormPet() {

  const [stage, setStage] = useState("intro");
  const [formData, setFormData] = useState({});
  const imageUploadRef = useRef(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedConfig, setCroppedConfig] = useState(null);

  const { apiAction } = useContext(APIProviderContext);

  const [isValidatingImage, setIsValidatingImage] = useState(false);
  const [isEnqueuing, setIsEnqueuing] = useState(false);
  const [isPersonalityOtherSelected, setIsPersonalityOtherSelected] = useState(false);
  const [personalityOtherValue, setPersonalityOtherValue] = useState("");

  const [isInterestsOtherSelected, setIsInterestsOtherSelected] = useState(false);
  const [interestsOtherValue, setInterestsOtherValue] = useState("");

  const navigate = useNavigate();

  async function handleSubmission(){

    var selfname = formData?.selfname;
    var selfphone = formData?.selfphone;
    var selfemail = formData?.selfemail;
    var name = formData?.name;
    var type = formData?.type;
    var age = formData?.age;
    var personality = formData?.personality;
    var activity = formData?.activity;
    var habit = formData?.habit;
    var relationshipwithdeath = formData?.relationshipwithdeath;
    var special = formData?.special;
    var toy = formData?.toy;
    var phrase = formData?.phrase;

    // Remove trailing and leading spaces
    selfname = selfname?.trim?.();
    selfphone = selfphone?.trim?.();
    selfemail = selfemail?.trim?.();
    name = name?.trim?.();
    type = type?.trim?.();
    age = age?.trim?.();
    activity = activity?.trim?.();
    personality = personality?.filter(item => item !== "Other");
    habit = habit?.trim?.();
    relationshipwithdeath = relationshipwithdeath?.trim?.();
    special = special?.trim?.();
    toy = toy?.trim?.();
    phrase = phrase?.trim?.();

    if(isPersonalityOtherSelected){
      personality = [...personality ?? [], personalityOtherValue];
    }

    if(stage === "intro"){
      setStage("yourself");
      return;
    }

    if(stage === "yourself"){

      if(!selfname){
        toast.error("Please fill in your name");
        return;
      }

      if(selfname?.length < 5){
        toast.error("Please fill in a longer name");
        return;
      }

      if(selfphone){
        if(selfphone?.length < 5){
          toast.error("Please fill in a longer phone number");
          return;
        }
  
        // Check if phone number is valid
        if(!phone(selfphone)?.isValid){
          toast.error("Please fill in a valid phone number");
          return;
        }
      }


      if(!selfemail){
        toast.error("Please fill in your email");
        return;
      }

      if(!EmailValidator.validate(selfemail)){
        toast.error("Please fill in a valid email");
        return;
      }

      setStage("photo");

    }

    if(stage === "photo"){

      imageUploadRef.current.click();
    }

    if(stage === "uploaded"){

      // Check if photo is uploaded
      if(!formData?.photo){
        toast.error("Please upload a photo");
        return;
      }

      try {

        if(isValidatingImage){
          return;
        }

        setIsValidatingImage(true);

        // Validate image 
        var image = await apiAction("POST", "task/image-validate-upload", formData, "formdata");

        setIsValidatingImage(false);

        if(image?.photoUrl){
          setFormData({...formData, photoUrl: image.photoUrl});
        } else {
          toast.error("Failed to validate image");
          return;
        }

      } catch(err){
        setIsValidatingImage(false);
        toast.error(err?.response?.data?.response || "Failed to validate image");
        return;
      }
      

      setStage("details");
      

    }

    if(stage === "details"){

      // Check if name, food, color, characteristic are filled
      if(!formData?.name){
        toast.error("Please fill in a name or nickname");
        return;
      }

      if(!formData?.type){
        toast.error("Please fill in a type of pet");
        return;
      }

      if(!formData?.age){
        toast.error("Please fill in an age");
        return;
      }

      if(!formData?.personality?.length){
        toast.error("Please select at least one personality trait");
        return;
      }

      if(!formData?.activity){
        toast.error("Please fill in an activity");
        return;
      }

      if(!formData?.habit){
        toast.error("Please fill in a habit");
        return;
      }

      if(!formData?.relationshipwithdeath){
        toast.error("Please fill in a relationship with death");
        return;
      }

      // Todo check here
      if(isEnqueuing){
        return;
      }

      setIsEnqueuing(true);

      // Add to queue
      apiAction("POST", "task/enqueue", {
        ...formData,
        selfname,
        selfphone,
        selfemail,
        name,
        type,
        age,
        personality,
        activity,
        habit,
        relationshipwithdeath,
        special,
        toy,
        phrase,
      })
      .then(data => {
        var newTaskUlid = data?.newTaskUlid;
        
        if(newTaskUlid){
          navigate(`/queue/${newTaskUlid}`);
        }

        setIsEnqueuing(false);

      })
      .catch(err => {
        console.error(err?.response?.data?.response ?? err);
        setIsEnqueuing(false);
        toast.error(err?.response?.data?.response || "Failed to add to queue");
      });

      
      
    }
  }

  function handleImageUpload(e){
    const file = e?.target?.files?.[0];

    if(!file){
      return;
    }

    // Check max file size - todo fix it later
    // if(file?.size > 3 * 1024 * 1024){
    //   toast.error("Please upload a photo smaller than 3MB");
    //   return;
    // }

    // Check if file is an image
    if(!file?.type?.includes("image")){
      toast.error("Please upload a valid image file");
      return;
    }

    setImageBlob(window.URL.createObjectURL(file));
    setFormData({...formData, photo: file});


    setStage("photo-crop");
  }
  
  return (
    <div className="w-full flex flex-col gap-4 text-center">
      <input type="file" className="hidden" accept="image/*" ref={imageUploadRef} onChange={handleImageUpload}  />
      <img src="/images/candle.png" className={
        classNames({
          "w-[150px] h-auto mx-auto": true,
          "!w-[70px]": stage === "uploaded",
        })
      } />
      {
        stage === "intro" && (
          <>
            <p className="text-[20px]">
            Would you like to see your loved pet in the largest digital Day of the Dead altar in the world?
            </p>
            <p>
            (you will need a photo of your loved pet to continue)
            </p>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
                Continue
              </button>
          </>
        )
      }
      {
        stage === "yourself" && (
          <div className="flex flex-col gap-4 text-left">
            <div className="bg-card p-4 rounded-lg text-center">
              <p className="text-[16px]">
              The Day of the Dead, or Día de los Muertos, is a vibrant celebration of life and memory, where families gather to honor the spirits of loved ones who have passed. This tradition, steeped in Mexican culture, reflects a deep reverence for the cycle of life and death, turning loss into a beautiful expression of love. Altars, adorned with marigolds, candles, and treasured mementos, create a bridge between worlds, inviting the departed to join the living in a joyful remembrance that reminds us that those we cherish are never truly gone.
              </p>
            </div>
            <p className="text-center font-semibold">
              First Tell us about yourself
            </p>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4">
              <div className="input-group">
                <label htmlFor="name">Full Name<RequiredField /></label>
                <Input id="name" placeholder="James Bond" value={formData?.selfname} 
                  maxLength={LIMITS?.selfname?.maxLength} 
                  onChange={(e) => setFormData({...formData, selfname: e.target.value})}  />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email<RequiredField /></label>
                <Input id="email" 
                  placeholder="james@bond.com"
                  maxLength={LIMITS?.selfemail?.maxLength}
                  value={formData?.selfemail} onChange={(e) => setFormData({...formData, selfemail: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="phone">Phone</label>
                <Input id="phone" 
                  placeholder="+1 234 567 8900"
                  maxLength={LIMITS?.selfphone?.maxLength}
                value={formData?.selfphone} onChange={(e) => {
                  // Only accept numbers spaces dashes and +
                  e.target.value = e.target.value.replace(/[^0-9\s\-+]/g, "");
                  setFormData({...formData, selfphone: e.target.value});
                }} />
              </div>
              <button onClick={() => handleSubmission()} className="ep-action-btn">
                Continue
              </button>
            </div>
          </div>
        )
      }
      {
        stage === "photo" && (
          <>
            <p className="font-bold">
              Now, let’s upload a photo of <br></br> your loved pet
            </p>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              Choose Photo
            </button>
          </>
        )
      }
      {
        stage === "photo-crop" && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col gap-4 p-4 items-center justify-center backdrop-blur-sm">
              <p className="text-white text-[18px] font-semibold">
                Crop your photo
              </p>
              <div className="w-[400px] aspect-[1] relative max-w-full">
              <Cropper
                image={imageBlob}
                crop={crop}
                zoom={zoom}
                aspect={9/16}
                minZoom={9/16}
                restrictPosition={false}
                onCropChange={setCrop}
                onCropComplete={(croppedArea, croppedAreaPixels)=>{

                  setCroppedConfig({
                    ...croppedConfig,
                    ...croppedAreaPixels,
                  });


                }}
                onZoomChange={setZoom}
                objectFit="horizontal-cover"
              />
              </div>
              <button onClick={async () => {
                var croppedImage = await getCroppedImg(imageBlob, croppedConfig);
                
                setImageBlob(window.URL.createObjectURL(croppedImage));
                setFormData({...formData, photo: croppedImage});
                setStage("uploaded");

              }} className="ep-action-btn">
                Save
              </button>
            </div>
          </>
        )
      }
      {
        stage === "uploaded" && (
          <>
            <p>
              This is the photo you chose
            </p>
            <img src={imageBlob} alt="loved one" className="rounded-lg" />
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              {
                isValidatingImage ? <Loader 
                  size={6}
                  color="#040316"
                /> : "Continue"
              }
            </button>
            <button onClick={() => {
              imageUploadRef.current.click();
            }} className="ep-action-btn">
              Choose another photo
            </button>
          </>
        )
      }
      {
        stage === "details" && (
          <>
            <p className="text-[18px] font-semibold">
            Finally, let’s create a calverita (poem) for your loved pet:
            </p>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4">
              <p className="text-[16px]">
              Calaveritas, playful verses written for the Day of the Dead, honor the departed with humor and wit. These poems, crafted as lighthearted epitaphs, remind us that laughter can transcend even death, celebrating life with a smile.
              </p>
            </div>
            <div className="flex flex-col gap-4 rounded-lg border border-border bg-card p-4 text-left">
            <div className="input-group">
                <label htmlFor="name">Pet&apos;s Name<RequiredField /></label>
                <Input maxLength={200} id="name" value={formData?.name} onChange={(e) => setFormData({...formData, name: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="type">Type of Pet<RequiredField /></label>
                <SelectWithOtherInput id="type" value={formData?.type} onChange={(e) => setFormData({...formData, type: e})} options={[
                  {label: "Dog", value: "Dog"},
                  {label: "Cat", value: "Cat"},
                  {label: "Bird", value: "Bird"},
                  {label: "Fish", value: "Fish"},
                  {label: "Rabbit", value: "Rabbit"},
                  {label: "Hamster", value: "Hamster"},
                ]} />
              </div>

              <div className="input-group">
                <label htmlFor="age">Pet&apos;s Age<RequiredField /></label>
                {/* <SelectWithOtherInput id="location" value={formData?.location} onChange={(e) => setFormData({...formData, location: e})} options={[
                  {label: "Mexico City", value: "Mexico City"},
                  {label: "New York", value: "New York"},
                  {label: "Los Angeles", value: "Los Angeles"},
                ]} /> */}
                <Input placeholder="10 years" id="age" value={formData?.age} onChange={(e) => setFormData({...formData, age: e.target.value})} />
              </div>
              <div className="input-group">
                <label htmlFor="personality">Personality<RequiredField /></label>
                <CheckboxGroup
                options={[
                  {label: "Playful", value: "Playful"},
                  {label: "Lazy", value: "Lazy"},
                  {label: "Energetic", value: "Energetic"},
                  {label: "Loyal", value: "Loyal"},
                  {label: "Independent", value: "Independent"},
                  {label: "Stubborn", value: "Stubborn"},
                  {label: "Shy", value: "Shy"},
                  {label: "Affectionate", value: "Affectionate"},
                  {label: "Curious", value: "Curious"},
                  {label: "Mischievous", value: "Mischievous"},
                ]} 
                  value={formData?.personality}
                  onChange={(e) => {
                    if(e?.length > 3){
                      toast.error("You can only select up to 3 personality traits");
                      return;
                    }
                    if(e?.includes("Other")){
                      setIsPersonalityOtherSelected(true);
                    } else {
                      setIsPersonalityOtherSelected(false);
                      setPersonalityOtherValue("");
                    }

                    setFormData({...formData, personality: e});
                  }}
                />
                {
                  isPersonalityOtherSelected && (
                    <Input placeholder="Other" value={personalityOtherValue} onChange={(e) => setPersonalityOtherValue(e.target.value)} className="mt-2" />
                  )
                }
              </div>
              <div className="input-group">
                <label htmlFor="activity">Favorite Activity<RequiredField /></label>
                <SelectWithOtherInput id="activity" value={formData?.activity} onChange={(e) => setFormData({...formData, activity: e})} options={[
                  {label: "Chasing Toys", value: "Chasing Toys"},
                  {label: "Sleeping", value: "Sleeping"},
                  {label: "Eating", value: "Eating"},
                  {label: "Going for walks", value: "Going for walks"},
                  {label: "Playing with other pets", value: "Playing with other pets"},
                  {label: "Barking/Meowing at strangers", value: "Barking/Meowing at strangers"},
                  {label: "Chewing Things", value: "Chewing Things"},
                  {label: "Cuddling", value: "Cuddling"},
                  {label: "Exploring outside", value: "Exploring outside"},
                ]} 
                />
              </div>
              <div className="input-group">
                <label htmlFor="habit">Quirky or Funny Habits<RequiredField /></label>
                <SelectWithOtherInput id="habit" value={formData?.habit} onChange={(e) => setFormData({...formData, habit: e})} options={[
                  {label: "Always begging for food", value: "Always begging for food"},
                  {label: "Chasing their tail", value: "Chasing their tail"},
                  {label: "Sleeping in weird places", value: "Sleeping in weird places"},
                  {label: "Stealing socks", value: "Stealing socks"},
                  {label: "Barking/meowing at the TV", value: "Barking/meowing at the TV"},
                  {label: "Always cracked jokes", value: "Always cracked jokes"},
                  {label: "Trying to catch their reflection", value: "Trying to catch their reflection"},
                  {label: "Refusing to listen to commands", value: "Refusing to listen to commands"},
                  {label: "Hiding during storms", value: "Hiding during storms"},
                ]} />
              </div>
              <div className="input-group">
                <label htmlFor="relationshipwithdeath">How Would They React to Meeting Death?<RequiredField /></label>
                <SelectWithOtherInput id="relationshipwithdeath" value={formData?.relationshipwithdeath} onChange={(e) => setFormData({...formData, relationshipwithdeath: e})} options={[
                  {label: "Playful", value: "Playful"},
                  {label: "Defiant", value: "Defiant"},
                  {label: "Curious", value: "Curious"},
                  {label: "Unbothered", value: "Unbothered"},
                  {label: "Scared", value: "Scared"},
                  {label: "Mischievous", value: "Mischievous"},
                ]} />
              </div>
              <div className="input-group">
                <label htmlFor="special">What Makes Them Special?</label>
                <Input id="special" value={formData?.special} onChange={(e) => setFormData({...formData, special: e.target.value})} placeholder="They always know when I'm feeling down and cuddle with me." />
              </div>
              <div className="input-group">
                <label htmlFor="toy">Favorite Toy or Object</label>
                <Input id="toy" value={formData?.toy} onChange={(e) => setFormData({...formData, toy: e.target.value})} placeholder="A squeaky bone" />
              </div>
              <div className="input-group">
                <label htmlFor="phrase">A Phrase You Would Say to Them Often</label>
                <Input id="phrase" value={formData?.phrase} onChange={(e) => setFormData({...formData, phrase: e.target.value})} placeholder="Who’s a good boy?" />
              </div>
            <button onClick={() => handleSubmission()} className="ep-action-btn">
              {
                isEnqueuing ? <Loader 
                  size={6}
                  color="#040316"
                /> : "Submit"
              }
            </button>
            </div>
          </>
        )
      }
    </div>
  );
}

export {
  RemembranceForm,
  RemembranceFormPet,
};