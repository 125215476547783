import React from "react";
import RemembranceForm from "../../components/form";
import QueueDisplay from "../../components/queue-display";
import classNames from "classnames";
import Header from "../../components/header";

function QueuePage() {
  return (
    <div className="relative flex min-h-screen w-full justify-center">
    <div className="relative w-full max-w-md p-4 flex flex-col gap-4 pb-[80px]">
    <Header />
      <img src="/images/candle.png" className={
        classNames({
          "w-[180px] h-auto mx-auto": true,
        })
      } />
      <QueueDisplay />
    </div>
  </div>
  );
}

export default QueuePage;