import React from "react";
import { RiseLoader } from "react-spinners";

function Loader({
    ...props
}) {
  return (
    <RiseLoader
    color="#fff"
    size={10}
    {...props}
    />
  );
}

export default Loader;