import React, { useContext, useEffect, useState } from "react";
import { APIProviderContext } from "../api";
import Loader from "../loader";
import { getFileUrl } from "../../lib/utils";

function FinishedItem() {

    const { apiAction } = useContext(APIProviderContext);

    const [finished, setFinished] = useState([]);
    const [loading, setLoading] = useState(false);

    function loadFinished(){
        apiAction("GET", "task/finished")
            .then(data=>{

                var finished = data?.latestFinished;

                setFinished(finished);
                setLoading(false);
            })
            .catch(err=>{
                console.error(err);
                setLoading(false);
            });
    }

    useEffect(() => {

        setLoading(true);

        loadFinished();

        var interval = setInterval(() => {
            loadFinished();
        }, 5000);

        return () => {
            if(interval){
                clearInterval(interval);
            }
        };
    }, []);

    console.log(finished?.processed_payload?.calaverita);

  return (
    <div 
    className="flex flex-col gap-4 min-h-[500px] border p-4 border-border rounded-lg bg-card">
        <h2 className="text-2xl font-bold">
            Altar
        </h2>
        <div className="flex gap-2">
            {
                loading ? (
                    <>
                        <div className="flex flex-col gap-2">
                            <Loader 
                                size={6}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {
                            finished?.name && (
                                <div className="w-full h-full flex flex-col md:flex-row gap-5">
                                    <div className="md:w-1/2 whitespace-pre-line">
                                        <p>{finished?.processed_payload?.calaverita}</p>
                                    </div>
                                    <div className="w-1/2">
                                        <img src={getFileUrl(finished?.payload?.photo)} className="w-full h-auto rounded-lg object-contain" />
                                    </div>
                                </div>
                            )
                        }
                        {
                            !finished?.name && (
                                <div className="flex flex-col italic text-gray-500 gap-2">
                                    <p>Nothing here yet!</p>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    </div>
  );
}

export default FinishedItem;