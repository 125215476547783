import React, { useContext, useEffect, useState } from "react";
import { APIProviderContext } from "../api";
import Loader from "../loader";
import { PendingItem } from "./pending";

function ProcessingItem() {

    const { apiAction } = useContext(APIProviderContext);

    const [processing, setProcessing] = useState();
    const [loading, setLoading] = useState(false);

    function loadProcessing(){
        apiAction("GET", "task/processing")
            .then(data=>{

                var processing = data?.processing;

                setProcessing(processing);
                setLoading(false);
            })
            .catch(err=>{
                console.error(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        
        loadProcessing();

        var interval = setInterval(() => {
            loadProcessing();
        }, 5000);

        return () => {
            if(interval){
                clearInterval(interval);
            }
        };
    }, []);

  return (
    <div className="flex flex-col gap-4 border p-4 border-border rounded-lg bg-card">
        <h2 className="text-2xl font-bold">
            We&apos;re generating a memory for you now!
        </h2>
        <div className="flex flex-col gap-2">
            {
                loading ? (
                    <>
                        <div className="flex flex-col gap-2">
                        <Loader 
                                size={6}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {
                            processing?.name && (
                                <PendingItem item={processing} />
                            )
                        }
                        {
                            !processing?.name && (
                                <div className="flex flex-col italic text-gray-500 gap-2">
                                    <p>Nothing here yet!</p>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    </div>
  );
}

export default ProcessingItem;