import React, { createContext, useContext, useState } from "react";
import qs from "qs";
import axiosRetry from "axios-retry";
import axios from "axios";

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000; // retry after 1, 2, 3, ... seconds
  },
});

const APIProviderContext = createContext();

const APIProvider = (props) => {
  const BASE_API_URL = process.env.REACT_APP_API_URL + "/";

  const [alias, setAlias] = useState(null);

  const makeRequest = async (method, url, data, params, headers) => {
    const request = {
      method,
      url,
      data,
      params,
      headers: {
        ...headers,
      },
    };
    return axios(request);
  };

  async function apiAction(method, action, params, type) {
    return new Promise(async (resolve, reject) => {
      try {
        if (type === "formdata") {
          var form = new FormData();
          console.log(params);
          for (var key in params) {
            if (params[key]) {
              form.append(key, params[key]);
            }
          }

          console.log(form.entries());

          var response = await makeRequest(
            method,
            `${BASE_API_URL}${action}`,
            form,
            {},
            {
              "Content-Type": "multipart/form-data",
            },
          );

          resolve(response.data);
        } else {
          var response = await makeRequest(
            method,
            `${BASE_API_URL}${action}`,
            qs.stringify(params),
          );
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  return (
    <APIProviderContext.Provider
      value={{
        BASE_API_URL,
        alias,
        setAlias,
        apiAction,
      }}
    >
      {props.children}
    </APIProviderContext.Provider>
  );
};
export { APIProvider, APIProviderContext };
