import { clsx} from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}


export function modulus(divident, divisor) {
    return ((divident % divisor) + divisor) % divisor;
  }
  
  export  function remove_prefix(string, prefix) {
    return string && string.startsWith(prefix)
      ? string.slice(prefix.length)
      : string;
  }
  
 
  
  export function makeID(length) {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  export  function isEmptyObject(obj) {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  }
  
  export  function limitTextSize(text, length = 20, suffix = "…") {
    if (!text) {
      return "";
    }
    if (text.length < length + 1) {
      return text;
    }
    return text.slice(0, length + 1) + suffix;
  }
  
  /**
   * Normalizes a given string of HTML to remove the Windows-specific "Fragment"
   * comments and any preceding and trailing content.
   *
   * @param {string} html the html to be normalized
   * @return {string} the normalized html
   */
  function removeWindowsFragments(html) {
    const startStr = "<!--StartFragment-->";
    const startIdx = html.indexOf(startStr);
    if (startIdx > -1) {
      html = html.substring(startIdx + startStr.length);
    } else {
      // No point looking for EndFragment
      return html;
    }
  
    const endStr = "<!--EndFragment-->";
    const endIdx = html.indexOf(endStr);
    if (endIdx > -1) {
      html = html.substring(0, endIdx);
    }
  
    return html;
  }
  
 
 export function isJsonString(str) {
    if (str === null || str === undefined) {
      return false;
    }
  
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  
 export const fromEntries = (pairs) => {
    if (Object.fromEntries) {
      return Object.fromEntries(pairs);
    }
    return pairs.reduce(
      (accum, [id, value]) => ({
        ...accum,
        [id]: value,
      }),
      {},
    );
  };
  
 export function removeTrailingSlash(str) {
    return str ? str.replace(/\/+$/, "") : str;
  }
  
 export  const segmenter =
    Intl.Segmenter && new Intl.Segmenter("en", { granularity: "sentence" });
  
 export function getSentencesFromText(str) {
    if (!str) {
      return [];
    }
  
    // Intl Segmenter is not available in Firefox
    if (segmenter) {
      return Array.from(segmenter.segment(str), (s) => s.segment);
    }
  
    return str.match(/[^\.!\?]+[\.!\?]+/g) ?? [];
  }
  
  export  function isDevModeOn(email) {
    if (email === "") {
      return false;
    }
  
    if (process.env.REACT_APP_NODE_ENV === "development") {
      return true;
    }
  
    if (localStorage.getItem(email + "-devMode") === "true") {
      return true;
    }
  
    return false;
  }
  
  export function capitalizeFirstLetter(string) {
    if (typeof string !== "string") return "";
  
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  export function getInitialReactState(key, defaultValue = "", isObject) {
    try {
      const value = localStorage.getItem("ep-react-state-" + key);
  
      if (value === null) {
        return defaultValue;
      }
  
      try {
        return JSON.parse(value);
      } catch (error) {
        if (isObject) {
          return defaultValue;
        }
        return value;
      }
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  }
  
  export function setInitialReactState(key, value, isObject = false) {
    try {
      if (isObject) {
        localStorage.setItem("ep-react-state-" + key, JSON.stringify(value));
        return;
      }
  
      localStorage.setItem("ep-react-state-" + key, value);
    } catch (error) {
      console.error(error);
    }
  }
  
  export function removeInitialReactStates(keys = []) {
    try {
      keys.forEach((key) => {
        localStorage.removeItem("ep-react-state-" + key);
      });
    } catch (error) {
      console.error(error);
    }
  }
  

  export const TASK_STATUS = {
    PENDING: "pending",
    PROCESSING: "processing",
    COMPLETED: "completed",
    SKIPPED: "skipped",
    FAILED: "failed",
  };

  export const PER_TASK_SECONDS = 45;

  export function getFileUrl(filePath) {
    return process.env.REACT_APP_R2_CDN_URL + "/" + filePath;
}

  