import React from "react";
import RemembranceForm from "../../components/form";
import {PendingItems} from "../../components/queue/pending";
import FinishedItem from "../../components/queue/finished";
import ProcessingItem from "../../components/queue/processing";

function FormPage() {
  return (
    <div className="flex flex-col min-h-screen w-full items-center p-4">
      {/* <h1 className="text-4xl font-bold mb-4">
        Altar
      </h1> */}
      <div className="w-full max-w-[1200px] p-4 flex flex-col md:flex-row gap-4">
        <div className="flex flex-col gap-4 flex-[2]">
          <FinishedItem />
          <ProcessingItem />
        </div>
        <div className="flex flex-col gap-4 flex-[1] overflow-hidden">
          <PendingItems />
        </div>
      </div>
    </div>
  );
}

export default FormPage;