import _APP from "../../../package.json";
import React, { createContext } from "react";
import { compareVersions } from "compare-versions";

const VersioningContext = createContext();

const Versioning = (props) => {
  function compareVersion(version) {
    return compareVersions(_APP.version, version) >= 0;
  }

  return (
    <VersioningContext.Provider
      value={{
        version: _APP.version,
        versionName: _APP.versionName,
        compareVersion: compareVersion,
      }}
    >
      {props.children}
    </VersioningContext.Provider>
  );
};
export { Versioning, VersioningContext };
