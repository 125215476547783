import React from "react";
import Loadable from "react-loadable";


function EmptyLoading() {
  return <></>;
}

const FormPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-form" */
      /* webpackMode: "lazy" */
      "./../form"
    ),
  loading: EmptyLoading,
});

const NotFound404 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-404" */
      /* webpackMode: "lazy" */
      "./../404"
    ),
  loading: EmptyLoading,
});

export {
  FormPage,
  NotFound404,
};
