import { Route } from "react-router-dom";
import "./App.scss";
import { HelmetProvider } from "react-helmet-async";
import { Versioning } from "./components/versioning/context";
import React, { Suspense } from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import { NotFound404, FormPage } from "./pages/lazy";
import { Toaster } from "react-hot-toast";
import { ConfigProvider } from "antd";
import LookPage from "./pages/look";
import { APIProvider } from "./components/api";
import QueuePage from "./pages/queue";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* <Route
        path="/"
        element={
          <LoginRequired redirect={"/login"}>
              <NavigationLayout />
          </LoginRequired>
        }
      >
        <Route path="/" element={<div>Hi</div>} />
      </Route>
      <Route
        path="login"
        element={
          <LoginRequired not redirect={"/"}>
              <Login />
          </LoginRequired>
        }
      />
      <Route
        path="forgot"
        element={
          <LoginRequired not redirect={"/"}>
              <ForgotPassword />
          </LoginRequired>
        }
      />
      <Route
        path="oauth"
        element={
          <LoginRequired bypass redirect={"/"}>
              <Oauth />
          </LoginRequired>
        }
      />
      <Route
        path="sign-up"
        element={
          <LoginRequired not redirect={"/"}>
              <Register />
          </LoginRequired>
        }
      />
      <Route
        path="logout"
        element={
          <LoginRequired redirect={"/login"}>
              <Logout />
          </LoginRequired>
        }
      /> */}
      <Route
        path="/"
        element={
              <FormPage />
        }
      />
      <Route
        path="/queue/:queueID"
        element={
              <QueuePage />
        }
      />
      <Route
        path="/look"
        element={
              <LookPage />
        }
      />
      <Route
        path="*"
        element={
            <NotFound404 />
        }
      />
    </>,
  ),
);



function App() {

  return (
    <HelmetProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#191818",
            colorText: "#F5F5F5",
            fontFamily: "Futura PT, serif",
            fontSize: 16,
          },
          components: {
            Input: {
              colorBgContainer: "#1E1E1E",
              colorBorder: "#444444",
              colorText: "#F5F5F5",
              colorBorderBg: "#444444",
              colorBorderSecondary: "#444444",
              activeBorderColor: "#818181",
              hoverBorderColor: "#818181",
              activeShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
            },
            Select: {
              colorBgContainer: "#1E1E1E",
              colorBorder: "#444444",
              colorText: "#F5F5F5",
              colorBorderBg: "#444444",
              colorBorderSecondary: "#444444",
              activeBorderColor: "#818181",
              hoverBorderColor: "#818181",
              activeShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
              optionActiveBg: "#444444",
              optionActiveShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
              optionSelectedBg: "#444444",
            },
            Checkbox: {
              
            },
          },
        }}
      >
      <Toaster />
        <Versioning>
            <APIProvider>
              <RouterProvider router={router} />
            </APIProvider>
        </Versioning>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
